.Main{
    width: 100%;
    height: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Container {
    width: 320px;
    height: 320px;
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
}
  
.Cell {
    border-radius: 4px;
    float: left;
    box-sizing: border-box;
    background-color: var(--Grey);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.CellInvalid {
    width: 18%;
    height: 18%;
    border-radius: 3px;
    float: left;
    box-sizing: border-box;
    background-color: var(--Red);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
}

.Clicked{
    background-color: transparent;
}

.ClickedLookOut{
    background-color: var(--lightGold);
    border-radius: 6px;
}

img{
    width: 80%;
    height: 80%;
    transition: 1s;
}

.AnimatedImages{
    animation: imgFadeIn 0.3s ease-in-out;
}

@keyframes imgFadeIn{
    0%{
        transform: rotate(0deg);
    }
    10%{
        transform: rotate(5deg);
    }
    50%{
        transform: rotate(0deg) scale(1.1);
    }
    80%{
        transform: rotate(-5deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes fadeIn{
    from{
        opacity: 0;
        filter: blur(5px);
    }
    to{
        opacity: 1;
        filter: blur(0px);
    }
}

.InfoContainer{
    width: 100%;
    height: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.HorizontalLine, .HorizontalLineFire {
    height: 5px;
    border-radius: 2px;
    transition: 1s;
    animation: fadeIn 0.5s ease-in-out;
}

.HorizontalLine{
    border: solid 2px var(--Black);
    background-color: var(--Black);
}

.HorizontalLineFire {
    border: solid 2px var(--Red);
    background-color: var(--Red);
}

.TopLight, .TopLightFire{
    width: 280px;
    height: 8px;
    border-radius: 4px;
    margin-top: 30px;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    transition: 1s;
    animation: fadeIn 0.5s ease-in-out;
}

.TopLight{
    border: solid 3px var(--Black);
}

.TopLightFire{
    border: solid 3px var(--Red);
}

.Instruction{
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
    letter-spacing: 3px !important;
    animation: fadeIn 0.5s ease-in-out;
}

.InstructionSecondary, .InstructionSecondaryButton{
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 3px !important;
    position: absolute;
    margin-top: 90px;
    animation: fadeIn 0.5s ease-in-out;
}

.InstructionSecondaryButton{
    opacity: 0.5;
    cursor: pointer;
    margin-top: 110px;
    font-size: 16px !important;
}

.InstructionSecondaryButton:hover{
    color: var(--Gold);
    opacity: 1;
}

.Cell, .CellInvalid, .imgFadeIn, .Dot, .HorizontalLine, .TopLight, .AnimatedImages, img {
    transform: translate3d(0, 0, 0);
}

.WaterStorage{
    width: 280px;
    margin: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
}

.WaterStorage h1{
    font-size: 18px;
    margin: -8px -8px 0 0;
}
.WaterStorage img{
    width: 50px;
    margin: -15px -15px;
}