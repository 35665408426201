.Container{
    height: 75%;
    background-color: var(--Background);
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 88%;
    margin-inline: auto;
    align-items: flex-start;
}

.Container h1{
    font-size: 24px;
    margin: 0 0 5px 0;
    cursor: pointer;
    z-index: 100;
}

.LevelContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 -40px 0;
}

.LevelContainer h1{
    font-size: 16px !important;
    cursor: pointer;
}

.LevelContainerInner:hover{
    opacity: 0.7;
}

.LevelContainerInner{
    min-width: 130px;
    background-color: var(--lightGold);
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
}

.LevelContainerInnerLocked{
    min-width: 130px;
    background-color: var(--Grey);
    padding: 10px 15px;
    border-radius: 15px;
}

.Locked{
    opacity: 0.4;
}

.Locked:hover{
    color: var(--Black) !important;
}

.BackHomeButton{
    margin-top: 35px !important;
}

.BackHomeButton:hover{
    color: var(--Gold);
}

.ShopButton{
    margin: -40px 0 0 0 !important;
}