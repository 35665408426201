.Container{
    background-color: var(--Background);
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 88%;
    margin-inline: auto;
}

.Container h1{
    font-size: 24px;
    cursor: pointer;
    z-index: 100;
}

.Title{
    width: 100%;
}

.Savings{
    font-size: 18px !important;
    margin: 0 0 20px 0;
}

.BackHomeButton{
    margin-top: 35px !important;
}

.BackHomeButton:hover{
    color: var(--Gold);
}

.ShopItem{
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: var(--lightGold);
    padding: 10px 0;
    border-radius: 15px;
    margin: 10px 0 0 0;
    cursor: pointer;
}

.ShopItem:hover{
    opacity: 0.7;
}

.UpgradeLevel{
    width: 26%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShopItemInner{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.ShopItemName{
    text-align: left;
    font-size: 18px !important;
    letter-spacing: 3px;
}

.UpgradeCost{
    font-size: 14px !important;
    letter-spacing: 3px;
    text-align: left;
}
