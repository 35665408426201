.Container{
    width: 100%;
    height: 55%;
    background-color: var(--Background);
    border-radius: 15px;
    animation: homeFadeIn 0.5s ease-in-out;
}

@keyframes homeFadeIn{
    from{
        opacity: 0;
        filter: blur(5px);
    }
    to{
        opacity: 1;
        filter: blur(0px);
    }
}

.Container h1{
    width: 100%;
    font-size: 30px;
    margin: 0 0 20px 0;
    cursor: pointer;
}

.Container h1:hover{
    color: var(--Gold);
}

.Locked{
    opacity: 0.4;
}

.Locked:hover{
    color: var(--Black) !important;
}
.ImageIconContainer{
    margin: 0 0 60px 0;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 20%;
    animation: homeFadeIn 0.5s ease-in-out;
}

.ImageIconContainer img{
    width: 80px;
    animation: homeImg 0.3s ease-in-out;
}

.LockedMenu{
    color: var(--Black);
    opacity: 0.4;
}

.LockedMenu:hover{
    color: var(--Black) !important;
}

.aboutButton{
    margin: 40px 0 0 0 !important;
}

@keyframes homeImg{
    0%{
        transform: rotate(0deg);
    }
    10%{
        transform: rotate(5deg);
    }
    50%{
        transform: rotate(0deg) scale(1.1);
    }
    80%{
        transform: rotate(-5deg);
    }
    100%{
        transform: rotate(0deg);
    }
}