@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --Black: #685555;
  --Grey: #68555528;
  --blueGrey: #6d859742;
  --Background: #fcf1df;
  --Green: #469a5b;
  --Gold: #d59b27;
  --lightGold: #d59b2739;
  --Red: #d57676;
}

h1{
  font-family: 'Quicksand';
  color: var(--Black);
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 5px;
  margin: 0;
}

p{
  font-family: 'Quicksand';
  color: var(--Black);
  font-weight: 600;
  font-size: 24px;
}

a{
  text-decoration: none;
}