.Container{
    width: 100%;
    height: 75%;
    background-color: var(--Background);
    border-radius: 15px;
    animation: fadeIn 0.5s ease-in-out;
}

.Container h1{
    width: 100%;
    font-size: 20px;
    margin: 0 0 15px 0;
}

.AreaTitle{
    margin: 0 0 38px 0 !important;
}

.TimeAndStars{
    background-color: var(--lightGold);
    width: 80%;
    padding: 30px 0;
    margin-inline: auto;
    border-radius: 15px;
}

.TimeAndStars h1{
    font-size: 20px !important;
    line-height: 30px;
}

.TextBox{
    margin: 0 0 38px 0;
}

.Stars{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -20px 0 0px 0;
}

.Stars img{
    margin: -35px;
    width: 150px;
}

.ContinueButton{
    margin-top: 85px !important;
    cursor: pointer;
}

.ContinueButtonRetry{
    margin-top: 40px !important;
    cursor: pointer;
}

.ContinueButton:hover, .ContinueButtonRetry:hover{
    color: var(--Gold);
}

@keyframes fadeIn{
    from{
        opacity: 0;
        filter: blur(5px);
    }
    to{
        opacity: 1;
        filter: blur(0px);
    }
}

.Love{
    margin: 30px 0 20px 0 !important;
}