.Container{
    width: 100%;
    height: 75%;
    background-color: var(--Background);
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
}

.Container h1{
    width: 100%;
    font-size: 18px;
    margin: 0 0 15px 0;
}

@keyframes fadeIn{
    from{
        opacity: 0;
        filter: blur(5px);
    }
    to{
        opacity: 1;
        filter: blur(0px);
    }
}

.TutorialContainers{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.TutorialContainers h1{
    margin-top: 60px;
    line-height: 40px;
}

.Cell, .SelectedCell, .SelectedCellTree, .lookOutCell {
    height: 60px;
    width: 60px;
    border-radius: 4px;
    float: left;
    box-sizing: border-box;
    background-color: var(--Grey);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    margin: 3px;
}

.lookOutCell{
    background-color: var(--lightGold) !important;
}

.SelectedCell{
    animation: cellPulse 2s infinite ease-in-out;
}

.SelectedCellTree{
    background-color: var(--Background) !important;
}

.Grid {
    width: 200px;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@keyframes cellPulse{
    0%{
        background-color: var(--Grey);
    }
    50%{
        background-color: var(--lightGold);
    }
    100%{
        background-color: var(--Grey);
    }
}

.NextButton{
    font-size: 28px !important;
    margin: 20px 0 0 0 !important;
    cursor: pointer;
}

.NextButton:hover{
    color: var(--Gold);
}

img{
    animation: imgFadeIn 0.3s ease-in-out;
}

@keyframes imgFadeIn{
    0%{
        transform: rotate(0deg);
    }
    10%{
        transform: rotate(5deg);
    }
    50%{
        transform: rotate(0deg) scale(1.1);
    }
    80%{
        transform: rotate(-5deg);
    }
    100%{
        transform: rotate(0deg);
    }
}