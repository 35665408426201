.Container{
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--Background);
    overflow: hidden;
}

.Main, .MainFire{
    width: 400px;
    height: 670px;
    border-radius: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: 1s;
}

.Main{
    background-color: var(--Background);
    border: solid 4px var(--Black);
}

.MainFire{
    background-color: #d576760e;
    border: solid 4px var(--Red);
}

.TitleArea{
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.InfoArea{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TitleArea{
    align-self: flex-start;
}

.InfoArea{
    align-self: flex-end;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Score, .HighScore{
    font-size: 20px;
    margin-bottom: 5%;
}

.SeedsRemaining{
    width: 100%;
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.Seed{
    font-size: 33px;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) { /* Adjust the breakpoint as needed */
    .Container{
        width: 100vw;
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--Background);
        overflow: hidden;
    }

    .Main, .MainFire {
        width: 100%;
        height: 100%;
        border-radius: 0; /* Optionally remove border radius */
        border: none !important;
    }
    .TitleArea{
        width: 100%;
        height: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .InfoArea{
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .TitleArea{
        align-self: flex-start;
    }
    
    .InfoArea{
        align-self: flex-end;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    .Score, .HighScore{
        font-size: 20px;
        margin-bottom: 5%;
    }
    
    .SeedsRemaining{
        font-size: 18px;
        margin: 0% 4% 2% 0;
        display: flex;
        align-items: center;
    }
    
    .SeedsRemaining img{
        width: 50px;
        margin-right: -5px;
    }
    
    .Seed{
        font-size: 33px;
        margin-right: 10px;
    }
}